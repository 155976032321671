import request from '@/util/request';

export function getRole() {
    return request({
        url: '/adminUser/getRole',
        method: 'get'
    });
};

export function getUserInfo() {
    return request({
        url: "/adminUser/myInfo",
        method: "get" 
    });
}