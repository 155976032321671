<template>
    <div class="app-wrapper">
        <SideNav class="sidebar-nav" />
        <div class="app-main">
            <router-view class="app-main-body"></router-view>
        </div>
    </div>
</template>

<script>
import SideNav from "./components/SideNav";

export default {
    name: "Layout",
    components: {
        SideNav,
    },
};
</script>

<style lang="less">
.app-wrapper {
    height: 100%;
    display: flex;

    .side-nav {
        position: relative;
        height: 100%;
        // flex-shrink: 0;
        // flex: 0 0 40px;
        display: flex;
    }

    .app-main {
        height: 100%;
        flex: 1 1 auto;
        overflow-y: auto;

        &::-webkit-scrollbar {
            height: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #d4dbe1;
            border-radius: 3px;
        }

        .app-main-body {
            min-width: 1298px;
            height: 100%;
            position: relative;
        }

        .main-container {
            position: absolute;
            top: 48px;
            left: 0;
            bottom: 0;
            right: 0;
            padding: 20px;
            box-sizing: border-box;
            background-color: #f5f7fa;
        }
    }
}
</style>