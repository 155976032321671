import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from './util/cookie' // get token from cookie

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  NProgress.start();
  document.title = to.meta.title;

  if (to.query.token && to.query.token.length && to.path != "/login") {
    store.dispatch('receiveToken', to.query.token);
    delete to.query.token;
  }

  const hasToken = getToken();

  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' });
      NProgress.done();
    } else {
      const hasRole = store.getters.role && store.getters.role.length > 0;
      if (hasRole) {
        next();
      } else {
        await store.dispatch('getRole');
        next({ ...to, replace: true });
        NProgress.done();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) < 0) {
        // next(`/login?redirect=${to.path}`);
        next("/login");
        NProgress.done();
    } else {
        next();
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
