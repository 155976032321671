import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ViewUI from 'view-design';
import VueAMap from 'vue-amap';
import './permission';
import 'view-design/dist/styles/iview.css';
import "@/styles/iview-override.less";
import "@/styles/variables.less";
import '@/assets/iconfont/iconfont.css';

Vue.config.productionTip = false;

Vue.use(ViewUI);
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
    key: process.env.VUE_APP_AMAP_KEY,
    plugin: ['MarkerClusterer'],
    // 默认高德 sdk 版本为 1.4.4
    v: '1.4.4'
});
window.BASE_API = process.env.VUE_APP_BASE_API;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
