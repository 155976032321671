<template>
    <div id="sidenav-menu">
        <div class="menu-left">
            <div class="logo"></div>
            <Menu :active-name="activeMenu" theme="dark" width="40" accordion @on-select="clickMenu">
                <Menu-Item v-for="route in navRouters" :name="route.name" class="sidenav-menu-item" :key="route.name">
                    <Tooltip :content="route.title" placement="right" :key="route.name" :transfer="true">
                        <i class="sidenav-menu-icon navmenu-icon-size nazaio-iconfont" :class="[route.icon]" />
                    </Tooltip>
                </Menu-Item>
            </Menu>

            <div class="sidenav-menu-bottom">
                <div class="sidenav-menu-item">
                    <Poptip placement="right-end" :transfer="true" trigger="hover" width="240" transfer-class-name="user-info">
                        <div class="sidenav-menu-avatar">
                            <Avatar icon="ios-person"></Avatar>
                        </div>
                        <div slot="content" id="userInfo">
                            <Tooltip :content="userInfo.email" max-width="250" placement="top-start"
                                ><h1>{{ userInfo.email }}</h1></Tooltip
                            >
                            <div class="secret">
                                <div class="row-item">
                                    <h2>Client ID</h2>
                                    <div class="copy">
                                        <Tooltip content="复制" placement="top"
                                            ><i @click="copy(userInfo.clientId, $event)" class="nazaio-iconfont nz-file-copy-fill"
                                        /></Tooltip>
                                    </div>
                                </div>
                                <Tooltip :content="userInfo.clientId" max-width="250" placement="top-start">
                                    <h3>
                                        {{ userInfo.clientId }}
                                    </h3>
                                </Tooltip>
                                <div class="row-item">
                                    <h2>Client Secret</h2>
                                    <div class="copy">
                                        <Tooltip content="复制" placement="top"
                                            ><i @click="copy(userInfo.clientSecret, $event)" class="nazaio-iconfont nz-file-copy-fill"
                                        /></Tooltip>
                                    </div>
                                </div>
                                <Tooltip :content="userInfo.clientSecret" max-width="250" placement="top-start">
                                    <h3>
                                        {{ userInfo.clientSecret }}
                                    </h3>
                                </Tooltip>
                            </div>
                            <h4 @click="logout"><i class="nazaio-iconfont nz-logout" /><span>登出</span></h4>
                        </div>
                    </Poptip>
                </div>
            </div>
        </div>
        <div v-if="hasSubMenus" class="menu-right">
            <h2>{{ activeRootMenu.title }}</h2>
            <ul>
                <li
                    v-for="subMenu in activeRootMenu.children"
                    :class="{ active: subMenu === activeSubMenu }"
                    :key="subMenu.name"
                    @click="clickSubMenu(subMenu.name)"
                >
                    <i class="nazaio-iconfont" :class="subMenu.icon" />
                    {{ subMenu.title }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { getUserInfo } from "@/api/admin-user";
import Clipboard from "clipboard";

export default {
    name: "SideNav",
    created() {
        this.getUserInfo();
        this.activeMenu = this.$route.matched[0].name;
    },
    data() {
        return {
            navRouters: [
                {
                    name: "lightpage",
                    icon: "nz-article-graphic-fill",
                    title: "轻页面",
                },
                // {
                //     name: "social-card",
                //     icon: "nz-id-card-fill",
                //     title: "社交名片",
                //     children: [
                //         {
                //             name: "knowledge-shelf",
                //             icon: "nz-book-opened",
                //             title: "知识货架",
                //         },
                //         {
                //             name: "card-template",
                //             icon: "nz-template-sms",
                //             title: "名片模板",
                //         },
                //         {
                //             name: "employee",
                //             icon: "nz-id-card",
                //             title: "企业员工",
                //         },
                //         {
                //             name: "company-tag",
                //             icon: "nz-tags",
                //             title: "企业标签",
                //         },
                //         {
                //             name: "miniprogram",
                //             icon: "nz-WeChat-small-program",
                //             title: "小程序设置",
                //         },
                //     ],
                // },
                {
                    name: "channel",
                    icon: "nz-setting-fill",
                    title: "授权平台",
                    children: [
                        {
                            name: "channel-list",
                            icon: "nz-link-app",
                            title: "授权平台管理",
                        },
                        {
                            name: "data-push-setting",
                            icon: "nz-share-forward",
                            title: "设置数据推送",
                        },
                    ],
                },
            ],
            activeMenu: "",
            userInfo: {
                email: "",
                clientId: "",
                clientSecret: "",
            },
        };
    },
    computed: {
        activeRootMenu() {
            for (let r in this.navRouters) {
                if (this.navRouters[r].name === this.$route.name) {
                    return this.navRouters[r];
                } else if (this.navRouters[r].children && this.navRouters[r].children.length) {
                    if (this.navRouters[r].children.findIndex((m) => m.name === this.$route.name) >= 0) {
                        return this.navRouters[r];
                    }
                }
            }
            return null;
        },
        activeSubMenu() {
            for (let r in this.navRouters) {
                if (this.navRouters[r].children && this.navRouters[r].children.length) {
                    let activeSubMenu = this.navRouters[r].children.find((m) => m.name === this.$route.name);
                    if (activeSubMenu) {
                        return activeSubMenu;
                    }
                }
            }
            return null;
        },
        hasSubMenus() {
            if (this.activeRootMenu && this.activeRootMenu.children && this.activeRootMenu.children.length) {
                return true;
            }
            return false;
        },
        activeChildren() {
            return this.activeRootMenu.children;
        },
    },
    methods: {
        clickMenu(active) {
            this.activeMenu = active;
            this.$router.push({ name: active });
        },
        clickSubMenu(name) {
            this.$router.push({ name: name });
        },
        getUserInfo() {
            getUserInfo().then((response) => {
                this.userInfo.email = response.email;
                this.userInfo.clientId = response.clientId;
                this.userInfo.clientSecret = response.clientSecret;
                if (response.bizCardEnable) {
                    this.navRouters.splice(1, 0, {
                        name: "social-card",
                        icon: "nz-id-card-fill",
                        title: "社交名片",
                        children: [
                            {
                                name: "knowledge-shelf",
                                icon: "nz-book-opened",
                                title: "知识货架",
                            },
                            {
                                name: "card-template",
                                icon: "nz-template-sms",
                                title: "名片模板",
                            },
                            {
                                name: "employee",
                                icon: "nz-id-card",
                                title: "企业员工",
                            },
                            {
                                name: "company-tag",
                                icon: "nz-tags",
                                title: "企业标签",
                            },
                            {
                                name: "miniprogram",
                                icon: "nz-WeChat-small-program",
                                title: "小程序设置",
                            },
                        ],
                    });
                }
            });
        },
        copy(text, e) {
            const clipboard = new Clipboard(e.target, {
                text: () => text,
            });
            clipboard.onClick(e);
        },
        logout() {
            this.$store.dispatch("logout").then(() => this.$router.push("/login"));
        },
    },
};
</script>

<style lang="less">
.user-info .ivu-poptip-body {
    padding: 0;
}

#userInfo {
    h1 {
        height: 50px;
        line-height: 50px;
        border-bottom: 1px solid #e8eaec;
        font-size: 16px;
        color: #0a2a4c;
        padding: 0 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;
        width: 223px;
    }

    .secret {
        border-bottom: 1px solid #e8eaec;
        margin-top: 4px;

        .row-item {
            overflow: hidden;
            margin-top: 8px;

            .copy {
                float: right;
                margin-right: 12px;

                i {
                    font-size: 14px;

                    &:hover {
                        color: @fc-main-color;
                    }
                }
            }
        }

        h2 {
            float: left;
            height: 22px;
            line-height: 22px;
            font-size: 12px;
            color: #8494a6;
            font-weight: 400;
            margin-left: 20px;
        }

        h3 {
            height: 22px;
            line-height: 22px;
            margin-left: 20px;
            margin-right: 20px;
            font-weight: 400;
            width: 183px;
            color: #395069;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    h4 {
        height: 42px;
        line-height: 42px;
        color: #969799;
        font-size: 14px;
        padding: 0 20px;
        font-weight: 400;
        cursor: pointer;

        span {
            margin-left: 12px;
            color: #646566;
        }
    }
}
</style>
<style lang="less" scoped>
.menu-left {
    flex: 0 0 40px;
}

.menu-right {
    flex: 0 0 200px;
    width: 200px;
    background-color: #0a2a4c;

    h2 {
        width: 64px;
        height: 22px;
        opacity: 1;
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        color: #ffffff;
        line-height: 22px;
        margin: 20px 0 0 12px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin-top: 22px;

        li {
            height: 28px;
            line-height: 28px;
            color: #9eafbf;
            font-size: 14px;
            margin-bottom: 8px;
            padding-left: 13px;
            cursor: pointer;

            i {
                margin-right: 9px;
            }

            &.active,
            &:hover {
                background-color: #003b61;
                color: #fff;
                position: relative;

                &:before {
                    content: " ";
                    width: 2px;
                    height: 28px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: #00b2a6;
                }
            }
        }
    }
}

#sidenav-menu {
    display: flex;
    position: relative;
    background-color: #0e3961; // padding-top: 48px;
    // overflow: hidden;
    .cutting-line {
        height: 1px;
        width: 14px;
        margin: 6px auto;
        background: #476886;
    }

    .sidenav-menu-item {
        border-right: 2px solid #0e3961;
        background-color: #0e3961;
        padding: 0;
        color: #9eafbf;

        &:hover {
            color: #41cdc0;
            //border-right: 2px solid @nz-color-block-bg;
        }
    }

    .sidenav-menu-item.ivu-menu-item-active.ivu-menu-item-selected {
        //background-color: #184d87;
        color: #41cdc0;
    }

    .sidenav-menu-icon,
    .sidenav-menu-avatar {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        cursor: pointer;
        display: block;
    }

    .sidenav-menu-bottom {
        position: absolute;
        width: 40px;
        bottom: 10px;
    }

    .logo {
        width: 32px;
        height: 32px;
        margin: 14px 4px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: 24px;
        background-color: rgba(255, 255, 255, 0.2);
        background-position: center center;
        background-image: url("../../assets/favicon.png");
    }

    .sidenav-menu-avatar {
        .ivu-avatar {
            background: rgba(255, 255, 255, 0.2);
            color: #0c2a4c;
        }
    }
}
</style>