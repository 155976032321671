import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout/Layout";

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        component: () => import('@/views/Login'),
        meta: {
            title: "CMS轻页面管理"
        }
    }, {
        path: "/",
        name: "home",
        redirect: "/lightpage/list"
    }, {
        name: "lightpage",
        path: "/lightpage",
        redirect: "/lightpage/list",
        component: Layout,
        meta: {
            title: "轻页面管理"
        },
        children: [
            {
                path: "list",
                component: () => import('@/views/lightpage/Index'),
                name: "lightpage-list",
                meta: {
                    title: "轻页面管理"
                }
            }, {
                path: "add",
                component: () => import('@/views/lightpage/Add'),
                name: "lightpage-add",
                meta: {
                    title: "新建轻页面"
                }
            }, {
                path: "edit/:id",
                component: () => import('@/views/lightpage/Add'),
                name: "lightpage-edit",
                meta: {
                    title: "编辑轻页面"
                }
            }, {
                path: "report/:id",
                component: () => import('@/views/lightpage/Report'),
                name: "lightpage-report",
                meta: {
                    title: "轻页面报表"
                }
            }
        ]
    }, {
        name: "social-card",
        path: "/social-card",
        redirect: "/social-card/knowledge-shelf",
        component: Layout,
        meta: {
            title: "社交名片"
        },
        children: [
            {
                path: "knowledge-shelf",
                component: () => import("@/views/social-card/KnowledgeShelf/Index"),
                name: "knowledge-shelf",
                meta: {
                    title: "知识货架"
                }
            }, {
                path: "knowledge-shelf/report/:id",
                component: () => import("@/views/social-card/KnowledgeShelf/Report"),
                name: "knowledge-shelf-report",
                meta: {
                    title: "知识货架报表"
                }
            }, {
                path: "knowledge-shelf/add",
                component: () => import("@/views/social-card/KnowledgeShelf/Add"),
                name: "knowledge-shelf-add",
                meta: {
                    title: "新建知识货架"
                }
            }, {
                path: "knowledge-shelf/edit/:id",
                component: () => import("@/views/social-card/KnowledgeShelf/Add"),
                name: "knowledge-shelf-edit",
                meta: {
                    title: "编辑知识货架"
                }
            }, {
                path: "card-template",
                component: () => import("@/views/social-card/CardTemplate/Index"),
                name: "card-template",
                meta: {
                    title: "名片模板"
                }
            }, {
                path: "card-template/add",
                component: () => import("@/views/social-card/CardTemplate/Add"),
                name: "card-template-add",
                meta: {
                    title: "新建名片模板"
                }
            }, {
                path: "card-template/edit/:id",
                component: () => import("@/views/social-card/CardTemplate/Add"),
                name: "card-template-edit",
                meta: {
                    title: "编辑名片模板"
                }
            }, {
                path: "employee",
                component: () => import("@/views/social-card/Employee/Index"),
                name: "employee",
                meta: {
                    title: "企业员工"
                }
            }, {
                path: "company-tag",
                component: () => import("@/views/social-card/CompanyTag/Index"),
                name: "company-tag",
                meta: {
                    title: "企业标签"
                }
            }, {
                path: "miniprogram",
                component: () => import("@/views/social-card/Miniprogram/Index"),
                name: "miniprogram",
                meta: {
                    title: "小程序设置"
                }
            },
        ]
    }, {
        name: "channel",
        path: "/channel",
        redirect: "/channel/channel-list",
        component: Layout,
        meta: {
            title: "授权平台"
        },
        children: [
            {
                path: "channel-list",
                component: () => import("@/views/channel/Index"),
                name: "channel-list",
                meta: {
                    title: "授权平台管理"
                }
            }, {
                path: "data-push-setting",
                component: () => import("@/views/channel/DataPush"),
                name: "data-push-setting",
                meta: {
                    title: "设置数据推送地址"
                }
            }
        ]
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;
